import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { TitleMain, Button,Main,Row ,TextButton, RowAndSpaceBetween, SubTitleSection, Text} from "../components/atomics"
import Section from "../components/Section"

const IndexPage = () => {
  return(
  <Layout>
    <SEO title="Home" pageId="home" />
      <Main>
        <div>
          <TitleMain>
          Utilize os melhores métodos para gestão de tarefas, hábitos e metas.
          </TitleMain>

          <Row style={{marginTop:25}}>
            <Button href={'https://play.google.com/store/apps/details?id=com.flynow&referrer=utm_source%3Ddozeroaobilhao%26utm_medium%3Ddozeroaobilhao%26utm_term%3Ddozeroaobilhao'} >
              <img src={require('../assets/images/playstore.png')} alt="playstore" className="icon-store"/>
              <TextButton> Baixar na Google Play</TextButton>
            </Button>
            <Button
              style={{marginLeft:10}}
              href={'https://apps.apple.com/app/apple-store/id1536269005?pt=122223947&ct=dozeroaobilhao&mt=8'}
            >
              <img src={require('../assets/images/applestore.png')} alt="applestore" className="icon-store"/>
              <TextButton> Baixar na App Store</TextButton>
            </Button>
            <Button 
              href="https://appflynow.com/plataforma-web" target="_blank" rel="noopener noreferrer"
              style={{marginLeft:10}}
            >
              <img src={require('../assets/images/web.png')} alt="web" className="icon-store"/>
              <TextButton> Acessar Versão Web</TextButton>
            </Button>
          </Row>
        </div>
        <img src={require('../assets/images/screen-flynow.png')} alt="aplicativo" className="img-main"/>
      </Main>

      <Section title="FUNCIONALIDADES" id="funcionalidades">
        <div>
          <RowAndSpaceBetween>
            <div style={{marginTop:40,minWidth:330}}>
              <SubTitleSection>
              Gerencie suas tarefas.
              </SubTitleSection>
              <Text style={{marginTop:30}}>
              Com o Flynow você consegue adicionar suas tarefas, atividades, compromissos e reuniões que você tiver. Além disso, você pode classificar estas tarefas de acordo com a Tríade do Tempo, além de poder escolher categorias, descrições, checklist (etapas da tarefa que precisam ser concluídas), horário, notificações, etc.
              </Text>
            </div>
            <img src={require('../assets/images/app/tasks.png')} alt="aplicativo" className="img-func"/>
          </RowAndSpaceBetween>
        </div>

        <div style={{marginTop:30}}>
          <RowAndSpaceBetween reverseWrap>
            <img src={require('../assets/images/app/habits.png')} alt="aplicativo" className="img-func"/>
            <div style={{marginTop:40,minWidth:330}}>
              <SubTitleSection>
              Gerencie seus hábitos.

              </SubTitleSection>
              <Text style={{marginTop:30}}>
              O Flynow te auxilia no processo de criação de hábitos. Para isso, você precisa saber qual é o gatilho (o que te faz iniciar o hábito), a rotina (qual ação você executa) e a recompensa (o que você ganha com a execução deste hábito?). Além disso, clicando no hábito, você consegue ver seu desempenho nele (há quantos dias você já está cumprindo o hábito, qual é a porcentagem de execução dele, etc).
              </Text>
            </div>
          </RowAndSpaceBetween>
        </div>

        <div>
          <RowAndSpaceBetween>
            <div style={{marginTop:40,minWidth:330}}>
              <SubTitleSection>
              Gerencie suas metas.
              </SubTitleSection>
              <Text style={{marginTop:30}}>
              Com o Flynow, você pode criar metas utilizando o método SMART, ou seja, definindo qual é a meta, o tamanho da meta, o porquê esta meta é importante, qual é o prazo para esta meta ser cumprida, um checklist para sua meta (quais são as tarefas que devem ser realizadas para alcançar a meta?), categorias, hábitos relacionados e tarefas relacionadas. Além disso, você consegue visualizar o progesso de suas metas.
              </Text>
            </div>
            <img src={require('../assets/images/app/goals.png')} alt="aplicativo" className="img-func"/>
          </RowAndSpaceBetween>
        </div>
        
        <div style={{marginTop:30}}>
          <RowAndSpaceBetween reverseWrap>
            <img src={require('../assets/images/app/statistics.png')} alt="aplicativo" className="img-func"/>
            <div style={{marginTop:40,minWidth:330}}>
              <SubTitleSection>
              Diversas estatísticas e Gamificação.

              </SubTitleSection>
              <Text style={{marginTop:30}}>
              Com o Flynow você recebe feedback sobre seu desempenho, como estatísticas sobre o percentual de atividades cumpridas, estatísticas específicas para cada tarefa e hábito, gráfico da sua evolução semanal, gráfico do histórico, ou seja, o total de pontos que você conquistou em cada semana, gráfico referente a tríade do tempo e roda da vida, e muito mais!
              </Text>
            </div>
          </RowAndSpaceBetween>
        </div>

        <div style={{marginTop:30}}>
          <RowAndSpaceBetween>
            <div style={{marginTop:40,minWidth:330}}>
              <SubTitleSection>
              Ranking Semanal, Mensal e Geral. 
              </SubTitleSection>
              <Text style={{marginTop:30}}>
              O Flynow utiliza o conceito de gamificação para motivar e engajar os usuários na realização de suas atividades. Além disso, o aplicativo possui o ranking semanal, mensal e geral. Ou seja, o ranking mostra os 30 usuários que mais conquistaram pontos durante o período referente ao tipo do ranking.
              </Text>
            </div>
            <img src={require('../assets/images/app/ranking.png')} alt="aplicativo" className="img-func"/>
          </RowAndSpaceBetween>
        </div>

        <div style={{marginTop:30}}>
          <RowAndSpaceBetween reverseWrap>
            <img src={require('../assets/images/app/widget.png')} alt="aplicativo" className="img-func"/>
            <div style={{marginTop:40,minWidth:330}}>
              <SubTitleSection>
              Widgets
              </SubTitleSection>
              <Text style={{marginTop:30}}>
              Com o widget é possível visualizar todas suas atividades do dia e marcá-las como concluída pela tela inicial do celular, sem necessidade de abrir o aplicativo.
              </Text>
            </div>
          </RowAndSpaceBetween>
        </div>

        <div style={{marginTop:30}}>
          <RowAndSpaceBetween>
            <div style={{marginTop:40,minWidth:330}}>
              <SubTitleSection>
              Relatórios Semanais e Mensais.
              </SubTitleSection>
              <Text style={{marginTop:30}}>
                Tenha acesso a relatórios semanais e mensais completos, com diversas estátisticas referentes ao período.
              </Text>
            </div>
            <img src={require('../assets/images/app/reports.png')} alt="aplicativo" className="img-func"/>
          </RowAndSpaceBetween>
        </div>

        <div style={{marginTop:50}}>
          <RowAndSpaceBetween reverseWrap>
            <img src={require('../assets/images/app/web.png')} alt="aplicativo" className="img-func" style={{marginLeft:-30}}/>
            <div style={{marginTop:40,minWidth:330}}>
              <SubTitleSection>
              Plataforma Web
              </SubTitleSection>
              <Text style={{marginTop:30}}>
              Com a Plataforma Web é possível gerenciar todas suas tarefas, hábitos e metas também pelo navegador. Além disso, a plataforma web possui todas as outras funcionalidades presentes no aplicativo.
              </Text>
            </div>
          </RowAndSpaceBetween>
        </div>

        <div style={{marginTop:50,marginBottom:50}}>
          <RowAndSpaceBetween>
            <div style={{marginTop:40,minWidth:330}}>
              <SubTitleSection>
              Extensão para navegador
              </SubTitleSection>
              <Text style={{marginTop:30}}>
              Com a extensão é possível visualizar e marcar como concluída rapidamente todas as suas tarefas, hábitos e metas pelo computador. A extensão está disponível para os navegadores Google Chrome e Firefox.
              </Text>
            </div>
            <img src={require('../assets/images/app/extension.png')} alt="aplicativo" className="img-func"/>
          </RowAndSpaceBetween>
        </div>
      </Section>

      <Section id="depoimentos" title="DEPOIMENTOS">
      <div style={{marginTop:30}}>
          <Text>
          É simplismente o melhor app de organização que eu já tive! Consigo organizar meu dia e o mês inteiro por ele! Ele é fácil de usar, tem uma interface linda e nos incentiva a cumprir nossos objetivos, além de ensinar como fazer metas e classificar as tarefas!
          </Text>
          <Text bold style={{marginTop:10,textAlign: 'right', color: '#444', fontSize:22}}>
          - Selena Maiko.
          </Text>
        </div>
        <div style={{marginTop:30}}>
          <Text>
            De todos os apps de gestão de tarefas que já instalei pra testar e usar, esse de longe é o melhor!!! Ele superou todas as minhas expectativas, porque é bastante intuitivo, muitas ferramentas super úteis, tem lembrete de agendamentos, dá dicas para criamos hábitos saudáveis no dia a dia, e ainda é gratuíto!!! Excelente!!! 10 pros criadores!
          </Text>
          <Text bold style={{marginTop:10,textAlign: 'right', color: '#444', fontSize:22}}>
          - Carol Correia.
          </Text>
        </div>
        <div style={{marginTop:30}}>
          <Text>
            Esse aplicativo trouxe muita clareza no meu dia. Consigo me organizar e cumprir minhas atividades com clareza. Parabéns aos Desenvolvedores.
          </Text>
          <Text bold style={{marginTop:10,textAlign: 'right', color: '#444', fontSize:22}}>
          - Livia Rodrigues.
          </Text>
        </div>
        <div style={{marginTop:30}}>
          <Text>
            Que app é esse? Extremamente sensacional, ele não te dá apenas a possibilidade de criar as metas, objetivos e classifica-los ele te explica o que é da exemplos, isso me ajudou a não confundir. Que amor, estou apaixonada.
          </Text>
          <Text bold style={{marginTop:10,textAlign: 'right', color: '#444', fontSize:22}}>
          - Renata Rodrigues.
          </Text>
        </div>
        <div style={{marginTop:30}}>
          <Text>
            Sem dúvidas, um dos melhores apps para quem quer aumentar a produtividade. Além de organizar o nosso dia e deixarmos mais produtivos, podemos ter acesso a um ranking de quem fez mais pontos. Muito Top!!!
          </Text>
          <Text bold style={{marginTop:10,textAlign: 'right', color: '#444', fontSize:22}}>
          - José Leandro
          </Text>
        </div>
        <div style={{marginTop:30}}>
          <Text>
          App maravilhoso, consigo organizar todas as minhas tarefas, hábitos e metas, nunca mais esqueci de realizar uma tarefa pq ele sempre me lembra. Testei muitos outros apps mas esse foi o único que atendeu as minhas expectativas          </Text>
          <Text bold style={{marginTop:10,textAlign: 'right', color: '#444', fontSize:22}}>
          - Ingryd Martins
          </Text>
        </div>
        <div style={{marginTop:30,marginBottom:30}}>
          <Text>
          Único app que mais atendeu minhas necessidades. Luto contra a depressão e estou determinada a ter uma rotina básica, pra iniciar, e o aplicativo irá me ajudar muito.
          </Text>
          <Text bold style={{marginTop:10,textAlign: 'right', color: '#444', fontSize:22}}>
          - Karine
          </Text>
        </div>
      </Section>
  </Layout>
)}

export default IndexPage
